import { Injectable, signal } from '@angular/core';
import { LocalService } from '../local/local.service';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {

  constructor(private local:LocalService){

  }

  darkModeSignal = signal<string>(this.local.getData('darkMode') || 'null');

  updateDarkMode() {
    const newValue = this.darkModeSignal() === "dark" ? "null" : "dark";
    this.local.saveData('darkMode', newValue);
    this.darkModeSignal.set(newValue);
  }
}
