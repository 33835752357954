<div class="form_div">
  <label [for]="inputId" class="text-vendblocblue dark:text-white">Password</label>
  <div class="flex relative items-center">
    <input
      class="p-3 pr-10 w-full bg-white rounded-md border dark:bg-vendblocprimayDark text-vendblocprimayDark dark:text-white"
      [formControl]="passwordControl"
      [type]="showPassword ? 'text' : 'password'"
      [id]="inputId"
      placeholder="Enter your password"
      (focus)="onPasswordFocus()"
      (blur)="onPasswordBlur()"
    />
    <span 
      class="absolute right-3 text-gray-500 cursor-pointer hover:text-vendblocpurple"
      (click)="toggleVisibility()">
      <i class="pi" [ngClass]="showPassword ? 'pi-eye' : 'pi-eye-slash'"></i>
    </span>
  </div>

  <div class="error-container">
    @if(passwordControl!.invalid && (passwordControl!.dirty || passwordControl!.touched || submitted)){
      <div class="flex flex-col items-start text-xs italic text-red-400">
        @if(passwordControl!.errors?.['required']){
          <span>Password is required</span> 
        }
        @if(passwordControl!.errors?.['minlength']){
          <span>Enter at least {{passwordControl!.errors?.['minlength'].requiredLength}} characters</span>
        }
        @if(passwordControl!.errors?.['pattern']){
          <span>Invalid password format</span>
        }
        @if(passwordControl!.errors && !passwordControl!.errors['required'] && !passwordControl!.errors['minlength'] && !passwordControl!.errors['pattern']){
          <span>Invalid password</span>
        }
      </div>
    }
  </div>
</div>
