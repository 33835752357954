import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-restaurant-card',
  standalone: true,
  imports: [],
  templateUrl: './restaurant-card.component.html',
  styleUrl: './restaurant-card.component.css'
})
export class RestaurantCardComponent {
  @Input() outlet : string = ''
  @Input() store : string = ''
  @Input() outletId : string = ''
  @Input() outletSlug : string = ''
  @Input() storeId : string = ''
  @Input() image : string = 'https://res.cloudinary.com/dw5cv0yz0/image/upload/v1718293821/VendBloc/kjaro_zjrmh2.png'
  @Input() rating: number = 4.5;
  @Input() deliveryTime: number = 15;
  @Input() deliveryFee: number = 300;

  constructor(private router:Router){}

  navigateToOutlet(){
    //this.router.navigate(['/order/'+this.outletId]);
    this.router.navigate(['/order/'+this.outletSlug]);
  }  
}

