<app-admin-layout>

    <app-breadcrumb 
      [items]="breadcrumbItems"
      [home]="homeItem"
    ></app-breadcrumb>

  <div class="flex flex-wrap gap-4">
    <div class="flex-1 min-w-[250px] ">
      <app-stat-card
        [cardName]="'Total Orders'"
        cardCount="150"
        [icon]="'shopping-cart'">
      </app-stat-card>
    </div>
    
    <div class="flex-1 min-w-[250px] ">
      <app-stat-card
        [cardName]="'Revenue'"
        [cardCount]="'$2,500'"
        [icon]="'money-bill'">
      </app-stat-card>
    </div>

    <div class="flex-1 min-w-[250px] ">
      <app-stat-card
        [cardName]="'Revenue'"
        [cardCount]="'$2,500'"
        [icon]="'money-bill'">
      </app-stat-card>
    </div>

    <div class="flex-1 min-w-[250px] ">
      <app-stat-card
        [cardName]="'Revenue'"
        [cardCount]="'$2,500'"
        [icon]="'money-bill'">
      </app-stat-card>
    </div>
  </div>

  <div class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
    <div class="p-4 rounded-lg border border-gray-200">
      <app-line-chart></app-line-chart>
    </div>
    <div class="p-4 rounded-lg border border-gray-200">
      <app-bar-chart></app-bar-chart>
    </div>
  </div>
</app-admin-layout>