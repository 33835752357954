import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseDTO } from '@models/index';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { KioskCreate } from '@models/kiosk/kiosk';

@Injectable({
  providedIn: 'root'
})
export class KioskService {
  private baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) { }

  public getKiosksByOutlet(outletId: string): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/Kiosk/get-by-outlet/' + outletId);
  }

  public getKioskByUserId(userId: string): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/Kiosk/get-by-userId/' + userId);
  }

  public getKioskById(id: number): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/Kiosk/' + id);
  }

  public deleteKiosk(id: number): Observable<ResponseDTO> {
    return this.http.delete<ResponseDTO>(this.baseUrl + 'api/Kiosk/' + id);
  }

  public createKiosk(kiosk: KioskCreate): Observable<ResponseDTO> {
    console.log(kiosk);
    return this.http.post<ResponseDTO>(this.baseUrl + 'api/Kiosk/', kiosk);
  }
}
