import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { AuthLayoutComponent } from "../../layouts/auth-layout/auth-layout.component";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { PasswordFieldComponent,TextInputFieldComponent } from '@components/shared';
import { LoginResponseDTO } from '@models/user/user';
import { ToastrService } from 'ngx-toastr';
import { KioskService } from '@data-access/services';
import { AuthService } from '@data-access/services';
import { LoginDTO } from '@models/user/user';
import { LoadingService } from '@data-access/services';
import { Router } from '@angular/router';
import { ResponseDTO } from '@models/response/response';
import { first } from 'rxjs';
import { Kiosk } from '@models/kiosk/kiosk';
import { LoginComponent } from '../login/login.component';


@Component({
  selector: 'app-login-kiosk',
  standalone: true,
  imports: [AuthLayoutComponent, CommonModule, ReactiveFormsModule, PasswordFieldComponent,TextInputFieldComponent],
  templateUrl: './login-kiosk.component.html',
  styleUrl: './login-kiosk.component.css'
})
export class LoginKioskComponent extends LoginComponent {
  override isKiosk: boolean = true;
}
