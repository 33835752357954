export enum ProductType {
    Perishable,
    NonPerishable,
}

export enum FieldType {
  Image,
  Tag,
  Text
}

export enum tagDataTypes {
  boolean = 'boolean'
}

export interface TableColumn {
  header: string;
  field: string;
  fieldType?: FieldType;
  useTag?: boolean;
  tagType?: tagDataTypes 
  tagMap?: Record<string | number, string>;
  isImage?: boolean;
  isSearchable?:boolean
}

export enum OrderSource{
  Online,
  Kiosk,
  Cashier
}

export enum OrderStatus {
    Cancelled,
    Pending,
    'Business approved',
    'Awaiting pickup',
    'Delivery in progress',
    Delivered,
    Completed
}

export const PaymentPercentage = {
    SquadPay: 1.2,
    Paystack:1.5,
    VendBloc:1.2
  };

export enum PaymentStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  FAILED = 'FAILED'
}