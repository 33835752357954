import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { User } from '@models/index';

@Component({
  selector: 'app-delivery-option',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './delivery-option.component.html',
  styleUrl: './delivery-option.component.css'
})
export class DeliveryOptionComponent {


  @Input() selectedDeliveryMode: number = 0;
  @Input() enableDelivery: boolean | undefined = false;
  @Input() user: User | null = null;
  @Input() uniqueId: string = '';
  
  @Output() selectedDeliveryModeEvent = new EventEmitter<number>();
  @Output() changeDeliveryAddressEvent = new EventEmitter<void>();
  @Output() toggleDeliveryInstructionsDialogEvent = new EventEmitter<void>();
  @Output() toggleVendorInstructionsDialogEvent = new EventEmitter<void>();

  deliveryInstructionsDialog: boolean = false;

  toggleDeliveryInstructionsDialog() {
    this.toggleDeliveryInstructionsDialogEvent.emit();
  }

  toggleVendorInstructionsDialog() {
    this.toggleVendorInstructionsDialogEvent.emit();
  }

  deliveryModeChanged(): void {
    this.selectedDeliveryModeEvent.emit(this.selectedDeliveryMode);
  }

  changeDeliveryAddress() {
    this.changeDeliveryAddressEvent.emit();
  }
}