import { Injectable } from '@angular/core';

interface PrintInvoiceData {
  orderNumber: string;
  outlet: string;
  store: string;
  date: string;
  items: {
    name: string;
    quantity: number;
    price: number;
    total: number;
    group: number;
  }[];
  subtotal: number;
  tax: number;
  total: number;
  customerName: string;
}

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  printInvoice(data: PrintInvoiceData, layout: 'simple' | 'tabular' = 'simple') {
    // Create a hidden iframe for printing
    const printFrame = document.createElement('iframe');
    printFrame.style.display = 'none';
    document.body.appendChild(printFrame);

    // Generate invoice HTML content based on layout
    const invoiceContent = layout === 'tabular' 
      ? this.generateTableInvoiceHTML(data)
      : this.generateInvoiceHTML(data);
    
    // Write content to iframe and print
    printFrame.contentDocument?.write(invoiceContent);
    printFrame.contentDocument?.close();

    // Wait for content to load before printing
    printFrame.onload = () => {
      printFrame.contentWindow?.print();
      // Remove iframe after printing
      setTimeout(() => {
        document.body.removeChild(printFrame);
      }, 1000);
    };
  }

  private generateInvoiceHTML(data: PrintInvoiceData): string {
    // Group items by their group number
    const groupedItems = data.items.reduce((groups, item) => {
      const group = (groups[item.group] || []);
      group.push(item);
      groups[item.group] = group;
      return groups;
    }, {} as { [key: number]: typeof data.items });

    // Generate items section for each group
    const itemGroups = Object.entries(groupedItems).map(([groupNum, items]) => `
      <div class="order-group">
        <h3>Order #${Number(groupNum) + 1}</h3>
        <div class="items">
          ${items.map(item => `
            <div class="item">
              <span>${item.name} x${item.quantity}</span>
              <span>$${item.total.toFixed(2)}</span>
            </div>
          `).join('')}
        </div>
      </div>
    `).join('');

    return `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            @page {
              margin: 0;
              size: 80mm 297mm;
            }
            body {
              font-family: 'Arial', sans-serif;
              width: 72mm;
              padding: 3mm;
              margin: 0;
              font-size: 12px;
            }
            .header {
              text-align: center;
            }
            .header h2, .header p {
              margin: 2px 0;
            }
            .order-group {
              margin-bottom: 20px;
            }
            .order-group h3 {
              font-size: 14px;
              margin: 10px 0;
              padding-bottom: 5px;
              border-bottom: 1px solid black;
            }
            .item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
            }
            .totals {
              border-top: 2px solid black;
              margin-top: 10px;
              padding-top: 10px;
            }
            .footer {
              text-align: center;
              margin-top: 20px;
              border-top: 1px dashed black;
              padding-top: 10px;
            }
          </style>
        </head>
        <body>
           <div class="header">
            <h2>${data.store}</h2>
            <p>${data.outlet}</p>
            <p>Order #: ${data.orderNumber}</p>
            <p>Date: ${data.date}</p>
          </div>
          
          ${itemGroups}

          <div class="totals">
            <div class="item">
              <span>Customer Name:</span>
              <span>${data.customerName}</span>
            </div>
            <div class="item">
              <strong>Total:</strong>
              <strong>$${data.total.toFixed(2)}</strong>
            </div>
          </div>

          <div class="footer">
            <p>Thank you for your purchase!</p>
          </div>
        </body>
      </html>
    `;
  }

  private generateTableInvoiceHTML(data: PrintInvoiceData): string {
    // Group items by their group number
    const groupedItems = data.items.reduce((groups, item) => {
      const group = (groups[item.group] || []);
      group.push(item);
      groups[item.group] = group;
      return groups;
    }, {} as { [key: number]: typeof data.items });

    // Generate tables for each group
    const itemTables = Object.entries(groupedItems).map(([groupNum, items]) => `
      <div class="order-group">
        <h3>Order #${Number(groupNum) + 1}</h3>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Qty</th>
              <th class="text-right">Price</th>
              <th class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            ${items.map(item => `
              <tr>
                <td>${item.name}</td>
                <td>${item.quantity}</td>
                <td class="text-right">$${item.price.toFixed(2)}</td>
                <td class="text-right">$${item.total.toFixed(2)}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </div>
    `).join('');

    return `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            @page {
              margin: 0;
              size: 80mm 297mm;
            }
            body {
              font-family: 'Arial', sans-serif;
              width: 72mm;
              padding: 3mm;
              margin: 0;
              font-size: 12px;
            }
            .header {
              text-align: center;
            }
            .header h2, .header p {
              margin: 2px 0;
            }
            .order-group {
              margin-bottom: 20px;
            }
            .order-group h3 {
              font-size: 14px;
              margin: 10px 0;
              padding-bottom: 5px;
              border-bottom: 1px solid black;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 10px 0;
            }
            th, td {
              text-align: left;
              padding: 3px;
              border-bottom: 1px dotted #ddd;
            }
            th {
              font-size: 10px;
              text-transform: uppercase;
            }
            .text-right {
              text-align: right;
            }
            .totals {
              margin-top: 10px;
              padding-top: 10px;
              border-top: 2px solid black;
            }
            .footer {
              text-align: center;
              margin-top: 20px;
              border-top: 1px dashed black;
              padding-top: 10px;
            }
          </style>
        </head>
        <body>
          <div class="header">
            <h2>${data.store}</h2>
            <p>${data.outlet}</p>
            <p>Order #: ${data.orderNumber}</p>
            <p>Date: ${data.date}</p>
          </div>
          
          ${itemTables}

          <div class="totals">
            <table>
              <tr>
                <td><strong>Total:</strong></td>
                <td class="text-right"><strong>$${data.total.toFixed(2)}</strong></td>
              </tr>
            </table>
          </div>

          <div class="footer">
            <p>Thank you for your purchase!</p>
          </div>
        </body>
      </html>
    `;
  }
} 