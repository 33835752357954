<app-outlet>
    
    <app-zorro-table [cols]="tableCols" [enableAdd]="true" (add)="showDialog()" [enableDelete]="true"
        (delete)="deleteProduct($event)" [tableData]="products" [enableEdit]="true"
        (edit)="showEditDialog($event)" tableNamePlural="Products" tableNameSingular="Product"></app-zorro-table>


    <app-dialog [visible]="addProductDialog" [header]="'Add Product'" (confirm)="saveProduct()" (cancel)="addProductDialog = false" (hide)="addProductDialog = false">
        <div class="flex flex-col gap-2 md:flex-row"> 
            <div class="form_div">
                <app-text-input-field [formSubmitted]="formSubmitted" [formControl]="createProductForm.controls.name"
                    ngDefaultControl inputType="text" label="Name" inputId="name" placeHolder="Enter product name">
                </app-text-input-field>
            </div>
            <div class="form_div">
                <app-dropdown-field [formSubmitted]="formSubmitted"
                    [formControl]="createProductForm.controls.productCategory" [options]="productCategories"
                    label="Category" inputId="categoryId" ngDefaultControl placeHolder="Select a Category">
                </app-dropdown-field>
            </div>
        </div> 

         <div class="flex flex-col gap-2 w-full md:flex-row">
            <div class="form_div">
                <app-dropdown-field ngDefaultControl [formSubmitted]="formSubmitted"
                    [formControl]="createProductForm.controls.productType" [options]="productTypes" label="Type"
                    inputId="productType" placeHolder="Select a Type">
                </app-dropdown-field>
            </div>
            <div class="form_div">
                <app-dropdown-field ngDefaultControl [formSubmitted]="formSubmitted" [formControl]="createProductForm.controls.unit"
                    [options]="units" label="Unit of Measurement" inputId="unit" placeHolder="Select a Unit">
                </app-dropdown-field>
            </div>
        </div>
        <div class="form_div">
            <app-file-upload [formControl]="createProductForm.controls.image" [formSubmitted]="formSubmitted"
                label="Product" ngDefaultControl [required]="false" [maxFileSize]="250000"
                (fileUploaded)="onUpload($event)"></app-file-upload>
        </div>
        <div class="form_div">
            <app-textarea-field [formSubmitted]="formSubmitted" [formControl]="createProductForm.controls.description"
                ngDefaultControl label="Description" inputId="description" placeHolder="Enter product description"
                [maxChars]="200" [showCharCount]="true"></app-textarea-field>
        </div> 
    </app-dialog>

    <app-dialog [visible]="editProductDialog" header="Edit {{selectedProduct?.name}}" width="50%" [showFooter]=true
        (confirm)="updateProduct()" (cancel)="closeEditDialog()" (hide)="closeEditDialog()">
            <div class="flex flex-row justify-center">
            </div>
            <div class="flex flex-col gap-2 md:flex-row">
                <div class="basis-1/2">
                    <div class="form_div">
                    <app-text-input-field [formSubmitted]="formSubmitted" [formControl]="editProductForm.controls.name"
                        ngDefaultControl inputType="text" label="Name" inputId="edit_name"></app-text-input-field>
                    </div>
                </div>

                <div class="basis-1/2">
                    <div class="form_div">
                        <app-dropdown-field ngDefaultControl [formSubmitted]="formSubmitted"
                            [formControl]="editProductForm.controls.productCategory" [options]="productCategories"
                            label="Category" inputId="categoryId" placeHolder="Select a Category">
                        </app-dropdown-field>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-2 md:flex-row">
                <div class="form_div">
                    <app-dropdown-field ngDefaultControl [formSubmitted]="formSubmitted"
                        [formControl]="editProductForm.controls.productType" [options]="productTypes" label="Type"
                        inputId="productType" placeHolder="Select a Type">
                    </app-dropdown-field>
                </div>
                <div class="form_div">
                    <app-dropdown-field ngDefaultControl [formSubmitted]="formSubmitted" [formControl]="editProductForm.controls.unit"
                        [options]="units" label="Unit of Measurement" inputId="unit" placeHolder="Select a Unit">
                    </app-dropdown-field>
                </div>
            </div>
            <div class="form_div">
                <app-file-upload [formControl]="editProductForm.controls.image" [formSubmitted]="formSubmitted"
                    label="Product" ngDefaultControl [required]="false" [maxFileSize]="100000"
                    (fileUploaded)="onUpload($event)"></app-file-upload>
            </div>
            <div class="form_div">
                <app-textarea-field ngDefaultControl [formSubmitted]="formSubmitted" [formControl]="editProductForm.controls.description"
                    label="Description" inputId="edit_description"
                    placeHolder="Enter product description" [maxChars]="200"
                    [showCharCount]="true"></app-textarea-field>
            </div>
    </app-dialog>
</app-outlet>