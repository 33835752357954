import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { AuthLayoutComponent } from '@components/layouts';
import { AuthService } from '@data-access/services';
import { ResponseDTO } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { TextInputFieldComponent } from '@components/shared';
import { toastrConfig } from '@utils/shared/shared';

@Component({
  selector: 'app-email-verification',
  standalone: true,
  imports: [ReactiveFormsModule, TextInputFieldComponent, RouterLink, AuthLayoutComponent],
  templateUrl: './email-verification.component.html',
  styleUrl: './email-verification.component.css'
})
export class EmailVerificationComponent {

  constructor(private authService: AuthService, private toastr: ToastrService) { }
  email = '';
  formSubmitted : boolean = false;

  requestVerificationForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  requestVerification() {
    this.formSubmitted = true;
    if(this.requestVerificationForm.invalid){
      return;
    }
    const formValue = this.requestVerificationForm.value;
    this.authService.requestEmailVerification(formValue.email as string).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message, 'Success', toastrConfig);
        }
        else {
          this.toastr.error(response.message, 'Error', toastrConfig);
        }
      },
      error: () => {
        this.toastr.error("Something went wrong", 'Error', toastrConfig);
      }
    });
  }
}
