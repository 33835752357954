<p-dialog 
  [(visible)]="visible"
  [header]="header"
  [modal]="true"
  [closable]=closable
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  [position]="position"
  (onHide)="onHide()"
  [breakpoints]="{'960px': '75vw', '640px': '90vw'}"
  [style]="{'width': width}"
  styleClass="p-dialog-custom"
  [appendTo]="'body'"
>
  <!-- Dialog Content -->
  <div class="px-1 md:py-2">
    <ng-content></ng-content>
  </div>

  <!-- Dialog Footer -->
  @if (showFooter) {
    <ng-template pTemplate="footer">
      <div class="flex gap-3 justify-end">
        <p-button 
          [label]="cancelLabel"
          severity="secondary"
          (onClick)="onCancel()"
          [style]="{'background-color': '#FFF', 'border': '1px solid #580c8b', 'padding': '6px 10px', 'color': '#580c8b'}"
        ></p-button>
        <p-button 
          [label]="confirmLabel"
          severity="primary"
          (onClick)="onConfirm()"
          styleClass="action-button"
        ></p-button>
      </div>
    </ng-template>
  }
</p-dialog>
