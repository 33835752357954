import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, OrderService, OutletService } from '@data-access/services';
import { Order, OrderReport, Outlet, ResponseDTO, UpdateOrderStatus, User } from '@models/index';
import { format } from 'date-fns';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { Subject, first, takeUntil } from 'rxjs';
import { OutletComponent } from '../outlet/outlet.component';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OrderInvoiceComponent } from "../../shared/order-invoice/order-invoice.component";
import { ZorroTableComponent } from "../../zorro-table/zorro-table.component";
import { FieldType, TableColumn } from '@utils/constants/enums';
import { tagDataTypes } from '@utils/constants/enums';
import { OrderSource } from '../../../utils/constants/enums';

@Component({
  selector: 'app-outlet-orders',
  standalone: true,
  templateUrl: './outlet-orders.component.html',
  styleUrl: './outlet-orders.component.css',
  imports: [CardModule, TableModule, ButtonModule, TagModule, PanelModule, OutletComponent, DialogModule, DropdownModule, FormsModule, OrderInvoiceComponent, ZorroTableComponent]
})
export class OutletOrdersComponent implements OnDestroy, OnInit {

  onCancelAction() {
  throw new Error('Method not implemented.');
  }
  onConfirmAction() {
  throw new Error('Method not implemented.');
  }

  private $destroy = new Subject<void>();
  outlet: Outlet | null = null;
  orders: Order[] = [];
  currentOrder: Order | any;
  showDetail: boolean = false;
  dialogVisible: boolean = false;
  searchQuery: string = '';
  user: User | null = null;

  constructor(private orderService: OrderService, private outletService: OutletService, private toastr: ToastrService, private authService: AuthService) {
    this.outletService.outlet$.pipe(takeUntil(this.$destroy)).subscribe((result) => {
      this.outlet = result;
    });
    this.fetchOutletOrders(this.outlet?.id as number);
  }

  closeDialog() {
    this.dialogVisible = false;
  }

  ngOnInit() {
    this.authService.user$.pipe(takeUntil(this.$destroy)).subscribe({
      next: (result) => {
        this.user = result as User;
      },
      error: () => {
      }
    });
  }

  tableCols: TableColumn[] = [
    { field: 'customerName', header: 'Customer' },
    { field: 'id', header: 'Order Id', isSearchable:true },
    { field: 'status', header: 'Status' },
    { field: 'paid', header: 'Paid', fieldType: FieldType.Tag, tagType: tagDataTypes.boolean },
    { field: 'deliveryMode', header: 'Delivery' },
    { field: 'formattedDate', header: 'Date' }
  ];

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  createRange(range: number) {
    return new Array(range)
  }

  searchOrder() {
    if (this.searchQuery.trim() === '') {
      this.fetchOutletOrders(this.outlet?.id as number);
    } else {
      this.orders = this.orders.filter(order => 
        order.id.toString().includes(this.searchQuery.trim())
      );
    }
  }

  showDialog(index: number) {
    this.currentOrder = this.orders[index];
    this.dialogVisible = !this.dialogVisible;
  }

  statusChanged(id: number) {
    this.updateOrderStatus(this.currentOrder?.id, id)
  }

  fetchOutletOrders(id: number) {
    this.orderService.getOutletOrders(id).pipe(takeUntil(this.$destroy)).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.toastr.success(result.message);
          const orders = result.data as OrderReport;
          if (orders.orders) {
            this.orders = orders.orders.map((o: Order) => ({ ...o, formattedDate: format(o.orderDate as Date, 'dd MMM yyyy, HH:mm'), customerName: o.orderSource === OrderSource.Online? o.customer.firstName : o.kiosk.userName }));
          }
        }
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  fetchOrder(id: number) {
    this.orderService.getOrder(id).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const order = result.data as Order;
          this.toastr.success(result.message);
          if (order) {
            const modifiedOrder = {
              ...order,
              formattedDate: format(new Date(order.orderDate), 'dd MMM yyyy'),
              customerName: order?.customer?.firstName
            };
            this.orders = [];
            this.orders.push(modifiedOrder);
          }
          else {
            this.toastr.error(result.message);
          }
        }
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  updateCurrentOrder(id: number, updatedOrder: Order) {
    const index = this.orders.findIndex(x => x.id === id);
    this.orders[index]["status"] = updatedOrder.status;
    this.currentOrder = this.orders[index];
  }

  updateOrderStatus(id: number, status: number) {
    const order: UpdateOrderStatus = { orderId: id, userId: this.user?.id as string, status };
    this.orderService.updateOrder(order).then((result: ResponseDTO) => {
      if (result.status) {
        this.toastr.success(result.message);
        this.updateCurrentOrder(id, result.data as Order);
      }
      else {
        this.toastr.error(result.message);
      }
    }).catch(error => {
      console.log(error);
      this.toastr.error("We are sorry, Something went wrong");
    });
  }
}
