<label for="" class="mb-1">{{label}} Image</label>
<div class="form_div">
    <div class="file-upload-wrapper">
        <input
            
            type="file" 
            class="file-input"
            accept="image/*"
            (change)="onFileSelect($event)"
            #fileInput
        >
        <button 
            class="upload-button" 
            type="button"
            (click)="fileInput.click()"
        >
            <i class="pi pi-upload"></i>
            {{buttonString}}
        </button>
    </div>
        
    <div class="error-container">
        @if(formControl!.invalid && (formControl!.dirty || formControl!.touched || formSubmitted)){
            <div class="flex flex-col items-start text-xs italic text-red-400">
                @if(formControl!.errors?.['required']){
                    <span>{{label}} is required</span> 
                }
                @if(formControl!.errors?.['fileSizeExceeded']){
                    <span>File size exceeds maximum limit of {{maxFileSizeFormatted}} {{maxFileUnit}}</span>
                    <span>Your uploaded file is {{fileSizeFormatted.toFixed(2)}} {{fileUnit}}</span>
                }
                @if(formControl!.errors && !formControl!.errors['required'] && !formControl!.errors['fileSizeExceeded']){
                    <span>Invalid input</span>
                }
            </div>
        } 
    </div>
</div>