<div
  class="px-1 py-2 h-[175px] rounded-xl border border-gray-200 dark:border-gray-700 transition-all duration-300 hover:shadow-md">
  <div class="flex flex-row justify-between items-center h-full">
    <!-- Left Content -->
    <div class="flex flex-col px-2 max-w-[70%] 2xl:px-4">
      <div class="flex flex-col justify-start space-y-1">
        <span class="text-lg font-semibold truncate lg:text-lg 2xl:text-2xl text-vendblocblue dark:text-white">{{ cardName }}</span>
        <span
          class="text-sm font-light 2xl:text-base text-vendblocblue dark:text-white line-clamp-2">{{product.productDescription}}
        </span>
        <div class="flex flex-row justify-between items-center py-1 2xl:py-2">
          <span class="font-normal text-md 2xl:text-xl text-vendblocgrey dark:text-white">Price</span>
          <span class="font-bold text-black dark:text-white text-md">{{ price }}</span>
        </div>
        <app-product-quantity [quantity]="product.orderQuantity"
          (incrementDecrementEvent)="handleIncrementDecrement($event)"
          (quantityChangedEvent)="handleDirectQuantityInput($event)"></app-product-quantity>
      </div>
    </div>

    <!-- Right Content -->
    <div class="relative z-50 flex flex-col h-full max-w-[30%] items-center justify-between">
      <div class="absolute top-2 right-2 cursor-pointer">
        <i class="pi pi-info-circle text-vendblocpurple dark:text-white hover:text-vendblocpurple/80" (click)="showProductInfo()"></i>
      </div>
      <div class="flex items-center justify-center h-[75%] w-full">
        <app-avatar-image [image]="product.productImage"></app-avatar-image>
      </div>
      <button (click)="addToCart(product.productId!)"
        class="px-1 py-2 text-sm text-white rounded-md lg:px-2 2xl:font-semibold lg:text-md bg-vendblocpurple">
        Add to Cart
      </button>
    </div>
  </div>
</div>

<!-- Product Info Dialog -->
<app-dialog [(visible)]="showInfoDialog" (hide)="showInfoDialog = false" (cancel)="showInfoDialog = false" header="{{product.product}} Details" width="40%" [showFooter]="false">
  <div class="flex flex-col space-y-4">
    <div class="flex justify-center">
      <img [src]="product.productImage" [alt]="product.product" class="w-32 h-32 rounded-lg">
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div>
        <p class="font-semibold">Name:</p>
        <p>{{product.product}}</p>
      </div>
      <div>
        <p class="font-semibold">Category:</p>
        <p>{{product.productCategory}}</p>
      </div>
      <div class="col-span-2">
        <p class="font-semibold">Description:</p>
        <p>{{product.productDescription}}</p>
      </div>
    </div>
  </div>
</app-dialog>