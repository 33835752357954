<div [ngClass]="darkModeService.darkModeSignal() === 'dark' ? 'dark' : ''" class="min-h-screen font-inter">
    <div class="flex flex-col min-h-screen">
        <!-- <app-header></app-header> -->
        
        <main class="flex-1 bg-white dark:bg-vendblocprimayDark">
            <router-outlet></router-outlet>
            <app-loading></app-loading>
            <app-confirm-dialog></app-confirm-dialog>
        </main>

        <app-footer></app-footer>
    </div>
</div>
