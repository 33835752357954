import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { CreateOrder, UpdateOrderStatus, VerifyPayment } from '../../../models/order/order';
import { ResponseDTO } from '../../../models/response/response';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OrderService {
  baseUrl :string = environment.baseUrl;
  constructor(private http:HttpClient) { }

  getProductsCategories(outletId:number):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/ProductCategory/get-by-outlet/'+outletId);
  }

  pickupOrder(order:UpdateOrderStatus):Observable<ResponseDTO>{
    return this.http.put<ResponseDTO>(this.baseUrl+'api/Order/update-order/'+order.orderId, order)
  }

  checkout(order:CreateOrder):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.baseUrl+'api/Order/checkout', order)
  }

  validateAndCreateOrder(order:CreateOrder):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.baseUrl+'api/Order/kioskCheckout', order)
  }

  getUserOrders(email:string):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/Order/get-orders-by-user/'+email);
  }

  getOutletOrders(id:number):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/Order/get-orders-by-outlet/'+id);
  }

  getPickUps():Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/Order/get-pickups');
  }

  getOutletOrdersWithDate(id:number, startDate:string, endDate:string):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/Order/get-orders-by-outlet/'+id+'?startDate='+startDate+'&endDate='+endDate);
  }

  getOrder(id:number):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.baseUrl+'api/Order/'+id);
  }

  verifyPayment(payment:VerifyPayment):Observable<ResponseDTO>{
    let url = `${this.baseUrl}api/Payments/verify-payment?reference=${payment.reference}&gateway=${payment.gateway}`;
    if(payment.orderId){
      url = `${url}&orderId=${payment.orderId}`
    }
    return this.http.get<ResponseDTO>(url);
  }

  updateOrder(order: UpdateOrderStatus): Promise<ResponseDTO> {
    return new Promise((resolve, reject) => {
      this.pickupOrder(order).pipe(first()).subscribe({
        next: (result: ResponseDTO) => {
          resolve(result);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

}
