import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Cart, Inventory } from '@models/index';
import { formatCurrency } from '@utils/shared/shared';
import { ProductQuantityComponent } from "../shared/product-quantity/product-quantity.component";
import { AvatarImageComponent } from "../shared/avatar-image/avatar-image.component";

@Component({
  selector: 'app-cart-item',
  standalone: true,
  imports: [ProductQuantityComponent, AvatarImageComponent],
  templateUrl: './cart-item.component.html',
  styleUrl: './cart-item.component.css'
})
export class CartItemComponent {
  @Input() cartItem!: Cart;
  @Input() currency: string | undefined = '';
  @Input() cart: Cart[] = [];
  @Output() incrementDecrementEvent = new EventEmitter<boolean>(); 
  @Output() cartItemEmitted = new EventEmitter<Cart>();
  @Output() quantityChangedEvent = new EventEmitter<number>();

  removeItem() {
    this.cartItemEmitted.emit(this.cartItem);
  }

  formatCurrency = formatCurrency;

  incrementDecrement(increment:boolean){
    this.incrementDecrementEvent.emit(increment);
  }

  quantityChanged(quantity:number){
    this.quantityChangedEvent.emit(quantity)
  }
}
