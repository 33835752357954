<app-outlet>

    <app-zorro-table [cols]="tableCols" [enableAdd]="true" (add)="showAddDialog()"
        (delete)="deleteInventory($event)" [enableEdit]="true" (edit)="showEditDialog($event)"
        [tableData]="inventories" tableNamePlural="Inventory Items" tableNameSingular="Inventory Item"></app-zorro-table>


    <app-dialog [visible]="addInventoryDialog" header="Add Inventory" width="50%" [showFooter]=true
        (confirm)="addInventory()" (cancel)="showAddDialog()" (hide)="'addInventoryDialog = false'">
        @if(addInventoryDialog){
        <div class="mb-2 form_div">
            <app-dropdown-field [enableFilter]="true" label="Product" [options]="products" inputId="categoryId"
                placeHolder="Select a product" [formControl]="createInventoryForm.controls.product"
                (onChangeEvent)="onProductChange($event)" ngDefaultControl [formSubmitted]="addFormSubmitted">
            </app-dropdown-field>
        </div>

        <div class="flex mb-2 space-x-2">
            <div class="flex flex-col w-[50%]">
                <app-text-input-field [formSubmitted]="addFormSubmitted" ngDefaultControl inputType="number"
                    label="Cost Price" inputId="cost" placeHolder="Cost Price"
                    [formControl]="createInventoryForm.controls.costPrice"></app-text-input-field>
            </div>
            <div class="flex flex-col w-[50%]">

                <app-text-input-field [formSubmitted]="addFormSubmitted" ngDefaultControl inputType="number"
                    label="Sales Price" inputId="sales" placeHolder="Sales Price"
                    [formControl]="createInventoryForm.controls.salesPrice"></app-text-input-field>
            </div>
        </div>

        <div class="mb-2 form_div">
            <app-text-input-field [formSubmitted]="addFormSubmitted" ngDefaultControl inputType="number"
                label="Quantity" inputId="addquantity" placeHolder="Quantity"
                [formControl]="createInventoryForm.controls.quantity"></app-text-input-field>
        </div>
        @if(perishableProduct){
        <div class="flex mb-2 space-x-2">
            <div class="flex flex-col w-[50%]">
                <app-text-input-field [formSubmitted]="addFormSubmitted" ngDefaultControl inputType="date"
                label="Manufacturing Date" inputId="manufacturingDate" placeHolder="Manufacturing Date"
                [formControl]="createInventoryForm.controls.manufacturingDate"></app-text-input-field>
            </div>
            <div class="flex flex-col w-[50%]">
                <app-text-input-field [formSubmitted]="addFormSubmitted" ngDefaultControl inputType="date"
                label="Expiring Date" inputId="expiringDate" placeHolder="Expiring Date"
                [formControl]="createInventoryForm.controls.expiringDate"></app-text-input-field>
            </div>
        </div>
        }
        <div class="mb-2 form_div">
            <app-textarea-field label="Remark" [formSubmitted]="addFormSubmitted" ngDefaultControl inputId="remark"
                placeHolder="Remark" [formControl]="createInventoryForm.controls.remark"
                maxLength="100"></app-textarea-field>
        </div>
        }
    </app-dialog>


    <app-dialog [visible]="editInventoryDialog" header="Edit {{currentInventory?.product}}" width="50%"
        [showFooter]=true (confirm)="editInventory()" (cancel)="editInventoryDialog = false" (hide)="editInventoryDialog = false">
        <div class="flex mb-2 space-x-2">
            <div class="flex flex-col w-[50%]">
                <app-text-input-field [formSubmitted]="editFormSubmitted" ngDefaultControl inputType="number"
                    label="Cost Price" inputId="cost" placeHolder="Cost Price"
                    [formControl]="editInventoryForm.controls.costPrice"></app-text-input-field>
            </div>
            <div class="flex flex-col w-[50%]">
                <app-text-input-field [formSubmitted]="editFormSubmitted" ngDefaultControl inputType="number"
                    label="Sales Price" inputId="sales" placeHolder="Sales Price"
                    [formControl]="editInventoryForm.controls.salesPrice"></app-text-input-field>
            </div>
        </div>

        <div class="mb-2 form_div">
            <app-text-input-field [formSubmitted]="editFormSubmitted" ngDefaultControl inputType="number"
                label="Quantity" inputId="editquantity" placeHolder="Quantity"
                [formControl]="editInventoryForm.controls.quantity"></app-text-input-field>
        </div>

        <div>
            @if(currentInventory?.active){
                <button (click)="toggleAvailable()" class="px-6 py-2 text-white bg-red-500 rounded-sm">Disable</button>
            }@else {
                <button (click)="toggleAvailable()" class="px-6 py-2 text-white bg-green-500 rounded-sm">Enable</button>
            }
        </div>
    </app-dialog>
</app-outlet>