<a (click)="navigateToOutlet()" class="cursor-pointer">
    <div class="w-[170px] md:w-[200px] 2xl:w-[250px] rounded-lg shadow-sm hover:shadow-md transition-shadow">
        <!-- Image Container -->
        <div class="relative h-[120px]">
            <img 
                [src]="image || 'assets/images/default-restaurant.jpg'" 
                [alt]="store"
                class="object-cover w-full h-full rounded-t-lg"
            />
            <!-- Profile Image -->
            <img 
                [src]="image" 
                class="absolute left-3 -bottom-4 w-8 h-8 rounded-full border-2 border-white"
                [alt]="store"
            />
        </div>

        <!-- Content Container -->
        <div class="p-3 space-y-1.5">
            <!-- Restaurant Name with proper left padding to align with logo -->
            <h3 class="pl-11 text-sm font-semibold text-gray-800 dark:text-gray-300">{{store}}</h3>

            <!-- Delivery Info -->
            <div class="flex justify-between items-center text-xs text-gray-500">
                <span class="flex items-center">
                    <span class="mr-1 pi pi-car"></span>
                    {{deliveryTime}} mins delivery
                </span>
                <span>Fee ₦{{deliveryFee}}</span>
            </div>

            <!-- Rating -->
            <div class="flex items-center space-x-2">
                <div class="flex space-x-1">
                    <span class="text-sm pi pi-star-fill text-vendblocyellow"></span>
                    <span class="text-sm pi pi-star-fill text-vendblocyellow"></span>
                    <span class="text-sm pi pi-star-fill text-vendblocyellow"></span>
                    <span class="text-sm pi pi-star-fill text-vendblocyellow"></span>
                    <span class="text-sm text-gray-300 pi pi-star-fill"></span>
                </div>
                <span class="text-sm text-gray-600">{{rating}}</span>
            </div>
        </div>
    </div>
</a>