import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

export const errorhandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const toast = inject(ToastrService);
  return next(req).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse) {
        let errorMessage = 'An error occurred';
        // Handle text responses
        if (typeof err.error === 'string') {
          errorMessage = err.error;
        }
        // Handle JSON responses
        else if (err.error?.title) {
          errorMessage = err.error.title;
        }
        else if (err.error?.message) {
          errorMessage = err.error.message;
        }

        if (err.status === 401) {  
          console.error('Unauthorized request:', err);
          errorMessage = 'Unauthorized access';
        } 
        else if(err.status === 400){
          // Use the parsed error message
          toast.error(errorMessage);
        }
        else if(err.status === 500){
          toast.error("We are terribly sorry, our servers are asleep");
        }
        else {
          console.error('HTTP error:', err);
          toast.error(errorMessage);
        }
      } else {
        console.error('An error occurred:', err);
        toast.error('An unexpected error occurred');
      }
      return throwError(() => err); 
    }))
};
