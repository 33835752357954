import { Component, Input } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';

@Component({
  selector: 'app-avatar-image',
  standalone: true,
  imports: [AvatarModule, AvatarGroupModule],
  templateUrl: './avatar-image.component.html',
  styleUrl: './avatar-image.component.css'
})
export class AvatarImageComponent {
  @Input() image: string = '';
  @Input() size: "normal" | "large" | "xlarge" | undefined = "xlarge";
  @Input() shape: "square" | "circle" | undefined = 'circle'; 
}
