import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AuthLayoutComponent } from '@components/layouts';
import { TextInputFieldComponent } from '@components/shared';
import { AuthService, LoadingService } from '@data-access/services';
import { ResponseDTO } from '@models/index';
import { toastrConfig } from '@utils/shared/shared';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';

@Component({
    selector: 'app-forgot-password',
    standalone: true,
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.css',
    imports: [AuthLayoutComponent, FormsModule, ReactiveFormsModule, RouterLink, TextInputFieldComponent]
})
export class ForgotPasswordComponent {
    forgotPasswordForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
      });

      forgotPasswordFormSubmitted = false;

      constructor(private authService: AuthService, private router: Router, private toastr: ToastrService,
        private loadingService: LoadingService) { }

      forgotPassword() {
        this.forgotPasswordFormSubmitted = true;
        if(this.forgotPasswordForm.invalid) {
          return;
        }
        this.authService.logOut();
        this.loadingService.show();
        const formValue = this.forgotPasswordForm.value;
        this.authService.ForgotPassword(formValue.email as string).pipe(first()).subscribe({
          next: (result: ResponseDTO) => {
            if (result.status) {
              this.toastr.success(result.message, 'Success', toastrConfig);
              this.router.navigate(['/login']);
            }
            else {
              this.toastr.error(result.message, 'Error', toastrConfig);
            }
            this.loadingService.hide();
          },
          error: () => {
            this.toastr.error("Something went wrong", "Error", toastrConfig);
            this.loadingService.hide();
          }
        })
      }
}
