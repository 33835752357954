import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogService, ConfirmDialogData } from '../../data-access/services/confirm-dialog/confirm-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule],
  template: `
    <p-dialog 
      [(visible)]="visible" 
      [modal]="true" 
      [closable]="true" 
      [style]="{width: '450px'}" 
      [header]="data?.title || 'Confirm Action'"
    >
      <p class="m-0 text-lg">{{data?.message || 'Are you sure you want to proceed?'}}</p>
      <ng-template pTemplate="footer">
        <div class="flex justify-between w-full">
          <button 
            pButton 
            type="button" 
            [label]="data?.cancelLabel || 'Cancel'" 
            [style]="{'color': data?.cancelTextColor || '#0F172A', 'border-color': data?.cancelBorderColor || '#580c8b'}"
            class="flex-1 mr-2 p-button-text p-button-secondary" 
            (click)="onCancel()"
          ></button>
          <button 
            pButton 
            type="button" 
            [label]="data?.confirmLabel || 'Confirm'" 
            class="flex-1 ml-2 text-white p-button-text p-button-primary" 
            [style]="{'background-color': data?.confirmColor || '#580c8b', 'border-color': data?.confirmColor || '#580c8b'}"
            (click)="onConfirm()"
          ></button>
        </div>
      </ng-template>
    </p-dialog>
  `,
  styles: [`
    // :host ::ng-deep .p-dialog .p-dialog-header {
    //   background-color: #0F172A;
    //   color: white;
    //   justify-content: center;
    // }
    :host ::ng-deep .p-dialog .p-dialog-content {
      padding: 2rem;
    }
    :host ::ng-deep .p-dialog .p-dialog-footer {
      padding: 1rem 1.5rem;
    }
    :host ::ng-deep .p-button {
      padding: 0.75rem 1.5rem;
    }
    :host ::ng-deep .p-button.p-button-secondary {
      color: #0F172A;
      border: 1px solid #0F172A;
    }
    :host ::ng-deep .p-button.p-button-primary {
      background-color: #0F172A;
      border-color: #0F172A;
    }
    :host ::ng-deep .p-button.p-button-primary:hover {
      background-color: #1E293B;
      border-color: #1E293B;
    }
    
    :host ::ng-deep .p-button.p-button-secondary:focus {
      box-shadow: none !important;
      border-color: #580c8b !important;
    }
  `]
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  visible: boolean = false;
  data: ConfirmDialogData | null = null;
  private subscription: Subscription | null = null;

  constructor(private confirmDialogService: ConfirmDialogService) {}

  ngOnInit() {
    this.subscription = this.confirmDialogService.dialogState$.subscribe(
      (data) => {
        this.data = data;
        this.visible = !!data;
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onConfirm() {
    this.confirmDialogService.close(true);
  }

  onCancel() {
    this.confirmDialogService.close(false);
  }
}
