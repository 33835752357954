import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { LocalService } from '../local/local.service';
import { CreateComplaint, LoginDTO, LoginResponseDTO, RegisterUser, ResetPasswordDTO, User } from '../../../models/user/user';
import { ResponseDTO } from '@models/index';
import { CredentialResponse } from 'google-one-tap';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private url:string = environment.baseUrl;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  private userSubject = new BehaviorSubject<User | null>(this.getInitialUserState());
  private userEmailSubject = new BehaviorSubject<string>('');

  user$:Observable<User | null> = this.userSubject.asObservable();
  userEmail$ = this.userEmailSubject.asObservable();
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable()
  constructor(private http : HttpClient, private local:LocalService) {
    this.setAuthenticated(this.isUserAuthenticated())
  }

  registerUser(data:RegisterUser): Observable<ResponseDTO> {
    return this.http.post<ResponseDTO>(this.url+'api/User/Register', data);
  }

  registerLoginUser(data:any): Observable<ResponseDTO> {
    return this.http.post<ResponseDTO>(this.url+'api/User/register-login', data);
  }

  createComplaint(model:CreateComplaint):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.url+'api/home/create-complaint', model)
  }

  loginUser(data:LoginDTO, isKiosk:boolean):Observable<ResponseDTO>{
    const loginUrl = isKiosk ? 'api/kiosk/login' : 'api/user/login';
    return this.http.post<ResponseDTO>(`${this.url}${loginUrl}`, data);
  }

  loginGoogleUser(data:CredentialResponse):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.url+'api/user/login-with-google', data);
  }

  updateContact(data:any):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.url+'api/user/update-contact', data);
  }

  resetPassword(data:ResetPasswordDTO):Observable<ResponseDTO>{
    return this.http.post<ResponseDTO>(this.url+'api/user/reset-password', data);
  }

  requestEmailVerification(email:string):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.url+'api/user/request-verification/'+email);
  }

  EmailVerification(user:string, code:string):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.url+'api/user/email-verification/'+user+'/'+code);
  }

  ForgotPassword(email:string):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.url+'api/user/Forgot-Password/'+email);
  }

  GetUserById(id:string):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.url+'api/user/get-user-by-id/'+id);
  }

  getEligibleReferralPackages(id:string):Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.url+'api/referral/get-eligible-referral/'+id);
  }

  GetDispatchers():Observable<ResponseDTO>{
    return this.http.get<ResponseDTO>(this.url+'api/user/get-dispatchers');
  }

  storeData(key:string, value:string):void{
    this.local.saveData(key, value);
  }

  fetchLocalData(key:string){
    return this.local.getData(key);
  }
  

  saveUserDetails(loginData:LoginResponseDTO){
    const decodedToken : any = jwtDecode(loginData.token);
    this.storeData('token', loginData.token);
    const newuser : User = {
      name : decodedToken.name as string,
      email: decodedToken.email as string,
      phone: decodedToken.phone as string,
      address:decodedToken.address as string,
      id : decodedToken.userId as string,
      role: decodedToken.role as string
    }
    this.setUser(newuser);
  }

  getUserDetails():User{
    const storedUserState = this.local.getData('user');
    return JSON.parse(storedUserState);
  }

  getUserRole():string{
    const storedUserState = this.local.getData('user');
    if(storedUserState){
      return JSON.parse(storedUserState).role;
    }
    return '';
  }

  isUserAuthenticated():boolean{
    const token = this.local.getData('token');
    if(!token) return false;
    const decodedToken = jwtDecode(token);
    const now = new Date();
    const expires = (decodedToken.exp! * 1000)
    const expiringDate = new Date(expires);
    if(expiringDate < now){
      this.logOut();
      return false
    }
    return true;
  }

  logOut():void{
    this.local.removeData('token');
    this.local.removeData('user');
    this.userSubject.next(null);
    this.setAuthenticated(false)
  }

  private getInitialAuthState(): boolean {
    const storedAuthState = this.local.getData('isAuthenticated');
    return storedAuthState ? JSON.parse(storedAuthState) : false;
  }

  setAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticatedSubject.next(isAuthenticated);
    this.local.saveData('isAuthenticated',JSON.stringify(isAuthenticated));
  }

  setUser(user:User):void{
    this.userSubject.next(user);
    this.local.saveData('user', JSON.stringify(user));
  }

  private getInitialUserState(): User | null {
    const storedUserState = this.local.getData('user');
    return storedUserState ? JSON.parse(storedUserState) : null;
  }

  setToken(token: string) {
    this.local.saveData('token', token);
    this.isAuthenticatedSubject.next(true);
  }
}
