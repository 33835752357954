import { OrderComponent } from '@components/order/order.component';
import { CartCardComponent } from "../shared/cart-card/cart-card.component";
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InfoComponent } from "../info/info.component";
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { BadgeModule } from 'primeng/badge';
import { UserLayoutComponent } from "../layouts/user-layout/user-layout.component";
import { OrderProductCardComponent } from "../shared/order-product-card/order-product-card.component";
import { AppDialogComponent } from "../shared/app-dialog/app-dialog.component";
import { DarkModeService } from '../../data-access/services/dark-mode/dark-mode.service';
import { PrintService } from '../../services/print.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OutletService } from '../../data-access/services/outlet/outlet.service';
import { OrderService } from '../../data-access/services/order/order.service';
import { AuthService } from '../../data-access/services/auth/auth.service';
import { LoadingService } from '@data-access/services/loading/loading.service';
import { InventoryService } from '@data-access/services/inventory/inventory.service';
import { ConfirmDialogService } from '@data-access/services/confirm-dialog/confirm-dialog.service';
import { LocalService } from '@data-access/services/local/local.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { CreateOrder, ResponseDTO, VerifyPayment } from '@models/index';
import { first } from 'rxjs';

interface SquadInstance {
  setup: () => void;
  open: () => void;
}

interface SquadResponse {
  // Transaction details
  transaction_id: string;         // Unique transaction ID from Squad
  transaction_ref: string;        // Your reference passed to Squad
  payment_status: string;         // Status of payment: 'success' | 'failed' | 'pending'
  merchant_ref?: string;          // Merchant reference if provided

  // Payment details
  amount: number;                 // Amount paid in kobo/cents
  currency: string;              // Currency code e.g., 'NGN'
  payment_type: string;          // Payment method used
  email: string;                 // Customer's email

  // Additional info
  gateway_response: string;      // Response message from payment gateway
  created_at: string;           // Transaction creation timestamp
  merchant_name?: string;       // Name of merchant
}

declare global {
  interface Window {
    squad: any;
  }
}

@Component({
  selector: 'app-kiosk',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CartCardComponent,
    AvatarModule,
    AvatarGroupModule,
    InfoComponent,
    DialogModule,
    BadgeModule,
    UserLayoutComponent,
    OrderProductCardComponent
  ],
  templateUrl: './kiosk.component.html',
  styleUrl: './kiosk.component.css'
})
export class KioskComponent extends OrderComponent {
  private squadInstance: SquadInstance | null = null;
  private isProcessingPayment = false;
  paid = false;
  customerName: string = '';

  constructor(
    toastr: ToastrService,
    public override orderService: OrderService,
    outletService: OutletService,
    route: ActivatedRoute,
    public override loadingService: LoadingService,
    authService: AuthService,
    inventoryService: InventoryService,
    public override local: LocalService,
    confirmDialogService: ConfirmDialogService,
    router: Router,
    printService: PrintService,
    public darkModeService: DarkModeService
  ) {
    super(
      toastr,
      orderService,
      outletService,
      route,
      loadingService,
      authService,
      inventoryService,
      local,
      confirmDialogService,
      router,
      printService
    );
  }

  override ngOnInit() {
    // this.enterFullscreen();
    super.ngOnInit();
  }

  private enterFullscreen() {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen()
        .catch(err => {
          console.error(`Error attempting to enable fullscreen: ${err.message}`);
        });
    } else if ((element as any).mozRequestFullScreen) { // Firefox
      (element as any).mozRequestFullScreen();
    } else if ((element as any).webkitRequestFullscreen) { // Chrome, Safari & Opera
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) { // IE/Edge
      (element as any).msRequestFullscreen();
    }
  }

  printInvoice() {
    // Flatten the cart array and map the items
    const flattenedCart = this.cart.flat();

    const invoiceData = {
      orderNumber: `ORD-${Date.now()}`,
      date: new Date().toLocaleString(),
      items: flattenedCart.map(item => ({
        name: item.product || 'Unknown Item', // Use product name or fallback
        quantity: item.orderQuantity || 0,
        price: item.salesPrice || 0,
        total: (item.salesPrice || 0) * (item.orderQuantity || 0),
        group: item.group
      })),
      subtotal: this.calculateSubtotal(),
      tax: this.calculateTax(),
      total: this.calculateTotal(),
      outlet: this.outlet?.name || '',
      store: this.outlet?.store || '',
      customerName: this.customerName || 'Walk-In Customer'
    };
    this.printService.printInvoice(invoiceData, 'simple');
  }

  private calculateSubtotal(): number {
    return this.cart.flat().reduce((sum, item) => {
      return sum + ((item.salesPrice || 0) * (item.orderQuantity || 0));
    }, 0);
  }

  override updateProfile() {
    if (this.customerName) {
      this.updateProfileDialog = false;
      this.checkout();
    } else {
      this.toastr.error('Please enter your name');
    }
  }

  private calculateTax(): number {
    return 0; // Assuming 10% tax
  }

  private calculateTotal(): number {
    return this.calculateSubtotal() + this.calculateTax();
  }

  initiateSquadPayment(amount: number, orderId:number) {
    if (this.isProcessingPayment) {
      return;
    }

    this.squadInstance = new window.squad({
      onClose: () => {
        this.isProcessingPayment = false;
      },
      onLoad: () => console.log("Widget loaded successfully"),
      onSuccess: (response: SquadResponse) => {
        console.log(response);
        this.isProcessingPayment = false;
        this.loadingService.show();
        const model:VerifyPayment = {
        reference : response.transaction_ref,
         gateway : 'squad',
         orderId: orderId
       }
        this.orderService.verifyPayment(model).subscribe({
          next: (response) => {
            this.loadingService.hide();
            this.toastr.success('Payment verified successfully, please print your invoice')
            setTimeout(() => {
              this.resetCart();
              window.location.reload();
            }, 5000);
            this.printInvoice();
          },
          error: (error) => {
            this.loadingService.hide();
            console.error('Payment verification failed:', error);   
            const errorMessage = error.error?.message || error.error || 'Payment verification failed';
            this.toastr.error(errorMessage);
          }
        });
      },
      key: environment.SQUAD_PUBLIC_KEY,
      email: this.user?.email,
      amount: Math.round(amount * 100),
      currency_code: "NGN"
    });

    this.isProcessingPayment = true;
    this.squadInstance?.setup();
    this.squadInstance?.open();
  }

  override checkout() {
    if (this.cart.length === 0) {
      this.toastr.error('Cart is empty');
      return;
    }
    if (!this.customerName) {
      this.toastr.warning('Please provide your details for the invoice');
      this.updateProfileDialog = true;
      return;
    }

    const order: CreateOrder = {
      orders: this.cart,
      outletId: this.outlet?.id ?? 0,
      customer: {
        email: this.user?.email || this.profileUpdateForm.value.email || '',
        address: this.user?.address || this.profileUpdateForm.value.address,
        phone: this.user?.phone || this.profileUpdateForm.value.phone
      },
      kioskId:(this.user?.id ?? 0) as number,
      amount: this.totalCost,
      deliveryCost: this.selectedDeliveryMode === 0 ? 0 : this.deliveryFee,
      orderCost: this.ordersCost,
      serviceCharge: this.serviceCharge,
      deliveryInstruction: this.deliveryInstructions,
      vendorInstruction: this.vendorInstructions
    };

    this.orderService.validateAndCreateOrder(order).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.loadingService.hide()
          this.toastr.success('Order verifcation Successful', 'Order Validated');
          console.log(result.data);
          if (!this.isProcessingPayment) {
            this.initiateSquadPayment(order.amount, result.data as number);
          }
        }
        else {
          this.toastr.error(result.message);
          this.loadingService.hide()
        }
      },
      error: () => {
        this.loadingService.hide()
      }
    });
  }
}
