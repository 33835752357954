<app-admin-layout>
    <app-breadcrumb [items]="breadcrumbItems" [home]="homeItem"></app-breadcrumb>
 
    <app-zorro-table [cols]="tableCols" [enableOpen]="true" (open)="navigateToOutlet($event)"
        [enableAdd]="enableAdd" (addButtonPressed)="showDialog()" [tableData]="outlets" tableNamePlural="Outlets"
        tableNameSingular="Outlet"></app-zorro-table>

    <p-dialog header="Add Outlet" [(visible)]="visible" position="top" [modal]="true" [style]="{ width: '50vw' }"
        [draggable]="false" [resizable]="false" styleClass="custom-dialog-header">
        <form [formGroup]="createOutletForm" (submit)="createOutlet()">
            <div class="flex flex-col mt-5 space-y-2 w-full">
                <div class="form_div">
                    <label for="">Store</label>
                    <div class="rounded-md border">
                        <p-dropdown [options]="stores" formControlName="storeId" placeholder="Select a Store"
                            optionLabel="name" inputId="storeId-label"></p-dropdown>
                    </div>
                </div>
                <div class="flex gap-4">
                    <div class="form_div">
                        <label for="">Outlet's Name</label>
                        <input class="p-3 rounded-md border" placeholder="Outlet Name" formControlName="name"
                            type="text" required />
                    </div>
                    <div class="form_div">
                        <label for="">Select Outlet's Currency</label>
                        <p-dropdown class="rounded-md border" [options]="currencies" formControlName="currency"
                            placeholder="Select a Currency" optionLabel="name" inputId="float-label"></p-dropdown>
                    </div>
                </div>

                <div class="form_div">
                    <app-file-upload [formControl]="createOutletForm.controls.image" label="Outlet" [required]="true"
                        [maxFileSize]="1000000" (fileUploaded)="onUpload($event)"></app-file-upload>
                </div>
                <div class="flex gap-4">
                    <div class="form_div">
                        <label for="">Opening Time</label>
                        <input class="p-3 rounded-md border" placeholder="Opening Time" formControlName="opening"
                            type="time" required />
                    </div>
                    <div class="form_div">
                        <label for="">Closing Time</label>
                        <input class="p-3 rounded-md border" placeholder="Closing Time" formControlName="closing"
                            type="time" required />
                    </div>
                </div>
                <div class="flex justify-end items-end w-full">
                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocpurple" type="submit">Create</button>
                </div>
            </div>
        </form>
    </p-dialog>
</app-admin-layout>