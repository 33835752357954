<app-auth-layout>
    <form [formGroup]="forgotPasswordForm" (submit)="forgotPassword()">
      <div class="flex flex-col items-center w-full">
        <div class="flex flex-col md:w-[100%] items-center">
          <h1 class="mb-4 text-3xl font-bold">Forgot Password</h1>
          <span class="text-center text-gray-500"
            >Enter your email to request a password reset</span
          >
        </div>
  
        <div class="form_div">
          <app-text-input-field [formSubmitted]="forgotPasswordFormSubmitted" ngDefaultControl label="Email" inputId="registerEmail"
          placeHolder="hello@vendbloc.com" [formControl]="forgotPasswordForm.controls.email"
          inputType="email"></app-text-input-field>
        </div>    
        <div class="form_div">
          <button
            class="create_button flex justify-center space-x-4 bg-vendblocpurple text-white hover:bg-[#FAFAFA] hover:text-vendblocpurple hover:shadow-lg duration-300"
            type="submit"
          >
            <!-- <div class="w-8 h-8 rounded-full border-t-4 animate-spin"></div> -->
            <span>Send</span>
          </button>
        </div>
        
        <div class="flex flex-row justify-center mt-6 space-x-1 w-full">
          <span class="text-gray-500 font-[400]">
           Remember your password ?
          </span>
          <a
            routerLink="/login"
            routerLinkActive="active"
            class="hover:underline font-[500] hover:cursor-pointer"
            >Login</a
          >
        </div>
        <!-- <div
          class="flex flex-row justify-center mt-6 space-x-1 w-full lg:justify-start"
        >
          <span class="text-gray-500 font-[400]">
            Request Email Verification ?
          </span>
          <a
            routerLink="/request-verification"
            routerLinkActive="active"
            class="hover:underline font-[500] hover:cursor-pointer"
            >Request</a
          >
        </div> -->
      </div>
    </form>
  </app-auth-layout>
  