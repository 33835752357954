import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [DialogModule, ButtonModule],
  templateUrl: './app-dialog.component.html',
  styleUrl: './app-dialog.component.css'
})
export class AppDialogComponent {
  @Input() visible: boolean = false;
  @Input() header: string = '';
  @Input() width: string = '50%';
  @Input() showFooter: boolean = true;
  @Input() confirmLabel: string = 'Confirm';
  @Input() cancelLabel: string = 'Cancel';
  @Input() position: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' = 'center';
  @Input() appendTo: boolean = false;
  @Input() closable: boolean = true;

  @Output() hide = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() visibleChange = new EventEmitter<boolean>();

  onHide() {
    this.hide.emit();
    this.visible = false;
    this.visibleChange.emit(false);
  }

  onConfirm() {
    this.confirm.emit();
    this.visibleChange.emit(false);
  }

  onCancel() {
    this.cancel.emit();
    this.visible = false;
    this.visibleChange.emit(false);
  }

}
