<app-outlet>

    <p-card header="Report">
        <app-dialog (confirm)="queryOrders()" [visible]="showConfigureDialog" [style]="{ width: '40vw' }"
            header="Configure Report" (hide)="showConfigureDialog = false" (cancel)="showConfigureDialog = false">
            <!-- I had to remove form cause of the side menu issue -->
                <div class="flex flex-col mb-2 space-x-2 w-full md:flex-row">
                    <div class="flex flex-col space-y-2 w-full">
                        <label for="">Report Type</label>
                        <p-dropdown class="w-auto rounded-lg border custom-dropdown" [options]="reports"
                            optionLabel="name" showClear="true" placeholder="Select a Type"></p-dropdown>
                    </div>
                </div>
                <div class="flex flex-col gap-2 md:flex-row">
                    <div class="form_div">
                        <label for="">Start Date</label>
                        <p-calendar [maxDate]="maxDate" [style]="{'width':'100%'}"
                            class="px-2 py-3 rounded-lg border custom-calendar"
                            [(ngModel)]="startDate" [showIcon]="true" inputId="startDate" appendTo="body">
                        </p-calendar>
                    </div>
                    <div class="form_div">
                        <label for="">End Date</label>
                        <p-calendar [maxDate]="maxDate" [style]="{'width':'100%'}"
                            class="px-2 py-3 rounded-lg border custom-calendar"
                            [(ngModel)]="endDate" [showIcon]="true" inputId="endDate" appendTo="body">
                        </p-calendar>
                    </div>
                </div>
        </app-dialog>

        <div class="mb-4">
            <p-panel>
                <div class="flex flex-col md:flex-row">
                    <div class="flex flex-row justify-between items-center space-x-2 w-full">
                        <div class="w-auto">
                            <button (click)="toggleConfigureDialog()"
                                class="border px-[10px] text-white  rounded-md text-center text-[15px] hover:bg-vendblocyellow text-[400] py-2 flex justify-center space-x-2 bg-vendblocpurple hover:text-[#0F172A] hover:shadow-lg duration-300"
                                type="submit">
                                <span>Configure Report</span>
                            </button>
                        </div>
                        <div>
                            <div class="flex flex-row justify-between mt-2 w-full">
                                <span class="text-sm text-black"><span class="font-bold text-vendblocpurple">Total
                                        Cost:</span> {{ total }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                @if(startDate && endDate){
                <div class="flex flex-col justify-between mt-2 w-full">
                    <span class="text-sm text-black"><span class="font-bold text-vendblocpurple">Start Date:</span>
                        {{formatDate(startDate)}}</span>
                    <span class="text-sm text-black"><span class="font-bold text-vendblocpurple">End Date:</span>
                        {{formatDate(endDate)}}</span>
                </div>
                }
            </p-panel>
        </div>

        <app-zorro-table [cols]="tableCols" [enableShow]="true" showStatusDropdown="false" (show)="showDialog($event)" [tableData]="orders"
            tableNamePlural="Orders" tableNameSingular="Order"></app-zorro-table>
    </p-card>

    <app-order-invoice (closeDialog)="closeDialog()" [showStatusDropdown]="false" [order]="currentOrder" [showDialog]="dialogVisible"></app-order-invoice>

</app-outlet>