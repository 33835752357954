import { Component, OnInit } from '@angular/core';
import { TableComponent } from "../table/table.component";
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { AuthService, OrderService } from '@data-access/services';
import { Order, ResponseDTO, UpdateOrderStatus, User } from '@models/index';
import { Subject, first, takeUntil } from 'rxjs';
import { format } from 'date-fns';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ToastrService } from 'ngx-toastr';
import { OrderStatus } from '@utils/index';
import { AdminLayoutComponent } from "../layouts/admin-layout/admin-layout.component";
import { ZorroTableComponent } from "../zorro-table/zorro-table.component";

@Component({
  selector: 'app-pickup',
  standalone: true,
  templateUrl: './pickup.component.html',
  styleUrl: './pickup.component.css',
  imports: [DialogModule, TableModule, AdminLayoutComponent, ZorroTableComponent]
})

export class PickupComponent implements OnInit {

  private $destroy = new Subject<void>();
  orders: Order[] = [];
  currentOrder: Order | any = null;
  dialogVisible: boolean = false;
  confirmPickUp: boolean = false;
  user: User | null = null;

  ngOnInit() {
   
    this.authService.user$.pipe(takeUntil(this.$destroy)).subscribe({
      next: (result) => {
        this.user = result as User;
      },
      error: () => {
      }
    });
  }

  constructor(private orderService: OrderService, private toastr: ToastrService, private authService:AuthService) {
    this.fetchOrders();
  }
  tableCols: PrimeTableColumn[] = [
    { field: 'outlet', header: 'Outlet' },
    { field: 'store', header: 'Store' },
    { field: 'status', header: 'Status' },
  ];



  pickUpEvent(): void {
    this.confirmPickUp = true;
  }

  showDialog(index: number) {
    this.confirmPickUp = false;
    this.currentOrder = this.orders[index];
    this.dialogVisible = true;
  }

  fetchOrders() {
    this.orderService.getPickUps().pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const resultOrders = result.data as Order[];
          this.orders = resultOrders.map((o: Order) => ({ ...o, formattedDate: format(o.orderDate as Date, 'dd MMM yyyy') }));
        }
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  updateCurrentOrder(id: number, updatedOrder :Order){
    const index = this.orders.findIndex(x=>x.id === id);
    this.orders[index] = updatedOrder;
    this.currentOrder = this.orders[index];
    this.confirmPickUp = false;
  }

  cancelPickup(id:number){
    const order: UpdateOrderStatus = { orderId: id, userId:this.user?.id as string, status:OrderStatus.Cancelled};
    this.orderService.updateOrder(order).then((result:ResponseDTO) => {
      if(result.status){
        this.toastr.success("Order pick up cancelled");
        this.updateCurrentOrder(id, result.data as Order);
      }
      else{
        this.toastr.error(result.message);
      }
    }).catch(error => {
      this.toastr.error("Something went wrong");
    });
  }

  pickUpOrder(id: number) {
    const order: UpdateOrderStatus = { orderId: id, userId:this.user?.id as string, status:OrderStatus['Delivery in progress']};
    this.orderService.updateOrder(order).then((result:ResponseDTO) => {
      if(result.status){
        this.toastr.success("order picked up");
        this.updateCurrentOrder(id, result.data as Order);
      }
      else{
        this.toastr.error(result.message);
      }
    }).catch(error => {
      this.toastr.error("We are sorry, Something went wrong");
    });
  }
}
