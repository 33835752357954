import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { DarkModeService } from '@data-access/services';
import { ConfirmDialogComponent } from "./components/shared/confirm-dialog.component";
import { CommonModule } from '@angular/common';
@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
    imports: [HttpClientModule,
    RouterOutlet,
    FormsModule,
    ReactiveFormsModule,
    FooterComponent,
    RouterModule,
    NgxSpinnerModule,
    LoadingComponent, ConfirmDialogComponent,CommonModule]
})


export class AppComponent  {
  title = 'VendBlocEase';
  constructor( public darkModeService: DarkModeService){
  }
}