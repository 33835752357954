import { ToastrConfig } from "ngx-toastr";


export function formatCurrency(amount: number, currency: string, decimalPlaces?:number) : string {
    return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency:currency,
        minimumFractionDigits: decimalPlaces ?? 2,
        maximumFractionDigits: decimalPlaces ?? 2
    }).format(amount);
}

export const toastrConfig: any = {
    timeOut: 3000,
    closeButton: true,
    progressBar: true,
    progressAnimation: 'decreasing',
    toastClass: 'ngx-toastr',
    tapToDismiss: true,
    maxOpened:2,
    autoDismiss:true
};
