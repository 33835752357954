<app-outlet>

  <div class="mb-4">
    <p-panel header="Search">
      <div class="flex flex-col md:flex-row">
        <div class="flex w-full md:w-[45%] 2xl:w-[70%] space-x-2">
          <div class="w-[80%]">
            <input [(ngModel)]="searchQuery"
              class="px-1 py-2 pl-2 w-full rounded-md border-2 md:pl-6 focus:outline-none placeholder:text-sm md:placeholder:text-base"
              type="text" placeholder="Order ID" />
          </div>
          <div class="w-auto">
            <button (click)="searchOrder()"
              class="border px-[18px]  rounded-md border-gray-500 text-center text-[17px] text-[400] py-2 flex justify-center space-x-2 bg-vendblocpurple text-white hover:bg-[#FAFAFA] hover:text-[#0F172A] hover:shadow-lg duration-300"
              type="submit">
              <span>Search</span>
            </button>
          </div>
          <div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
  
  <app-zorro-table [cols]="tableCols" [enableEdit]="false" [enableShow]="true" (show)="showDialog($event)"
    [tableData]="orders" tableNamePlural="Orders" tableNameSingular="Order"></app-zorro-table>

  <app-order-invoice (closeDialog)="closeDialog()" (statusChangeEvent)="statusChanged($event)" [order]="currentOrder" [showDialog]=dialogVisible></app-order-invoice>

</app-outlet>