<app-outlet>
    <app-zorro-table [cols]="tableCols" (add)="toggleAddKioskDialog()" [enableAdd]="true" (delete)="deleteKiosk($event)" [enableDelete]="true"
        [tableData]="kiosks" tableNamePlural="Kiosks" tableNameSingular="Kiosk"></app-zorro-table>

    <app-dialog [visible]="addKioskDialog" (onHide)="addKioskDialog = false" [showFooter]="true" (confirm)="createKiosk()" [style]="{ width: '450px' }" header="Add Kiosk" styleClass="p-fluid">
        <div class="flex flex-row gap-4">
            <div class="form_div">
                <app-text-input-field [formSubmitted]="formSubmitted" [formControl]="createKioskForm.controls.name"
                    ngDefaultControl inputType="text" label="Name" inputId="name" placeHolder="Enter kiosk name">
                </app-text-input-field>
            </div>
        </div>
        <div class="flex flex-row gap-4">
            <div class="form_div">
                <app-text-input-field [formSubmitted]="formSubmitted" [formControl]="createKioskForm.controls.username"
                    ngDefaultControl inputType="text" label="Username" inputId="username" placeHolder="Username">
                </app-text-input-field>
            </div>
            <div class="form_div">
                <app-password-field [submitted]="formSubmitted" [passwordControl]="createKioskForm.controls.password"
                    ngDefaultControl inputId="password" label="Password" placeHolder="**********">
                </app-password-field>
            </div>
        </div>
    </app-dialog>

    <app-dialog [visible]="changeKioskPasswordDialog" (onHide)="changeKioskPasswordDialog = false" [showFooter]="true" (confirm)="createKiosk()" [style]="{ width: '450px' }" header="Add Kiosk" styleClass="p-fluid">
    
        <div class="flex flex-row gap-4">
            <div class="form_div">
                <app-password-field [submitted]="formSubmitted" [passwordControl]="changePasswordForm.controls.password"
                    ngDefaultControl inputId="password" label="Password" placeHolder="**********">
                </app-password-field>
            </div>
            <div class="form_div">
                <app-password-field [submitted]="formSubmitted" [passwordControl]="changePasswordForm.controls.changePassword"
                    ngDefaultControl inputId="password" label="Password" placeHolder="**********">
                </app-password-field>
            </div>
        </div>
    </app-dialog>
</app-outlet>