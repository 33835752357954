<div class="form_div">
  @if(label !== ""){
    <label [for]="inputId" class="text-vendblocblue dark:text-white">{{label}}</label>
  }
  <input
    class="p-3 bg-white rounded-md border text-vendblocprimayDark dark:text-white dark:bg-vendblocprimayDark focus:dark:bg-vendblocprimayDark active:dark:bg-vendblocprimayDark"
    [placeholder]="placeHolder"
    [formControl]="formControl"
    [type]="inputType"
    [id]="inputId"
  />

  <div class="error-container">
    <!-- min-h-[20px] -->
    <!-- Debug info -->
    <!-- <div class="text-xs text-gray-500">
      Invalid: {{formControl!.invalid}}, Dirty: {{formControl!.dirty}}, Touched: {{formControl!.touched}}, Submitted: {{formSubmitted}}
    </div> -->

    @if(formControl!.invalid && (formControl!.dirty || formControl!.touched || formSubmitted)){
      <div class="flex flex-col items-start text-xs italic text-red-400">
        @if(formControl!.errors?.['required']){
          <span>{{label}} is required</span> 
        }
        @if(formControl!.errors?.['email']){
          <span>Please enter a valid email</span>
        }
        @if(formControl!.errors?.['minlength']){
          <span>Enter at least {{formControl!.errors?.['minlength'].requiredLength}} characters</span>
        }
        @if(formControl!.errors?.['maxlength']){
          <span>Maximum {{formControl!.errors?.['maxlength'].requiredLength}} characters allowed</span>
        }
        @if(formControl!.errors?.['pattern']){
          <span>Invalid format</span>
        }
        @if(formControl!.errors && !formControl!.errors['required'] && !formControl!.errors['email'] && !formControl!.errors['minlength'] && !formControl!.errors['maxlength'] && !formControl!.errors['pattern']){
          <span>Invalid input</span>
        }
      </div>
    } 
  </div>
</div>
