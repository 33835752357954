import { Component } from '@angular/core';
import { ConfirmDialogService, OutletService } from '@data-access/services';
import { KioskService } from '@data-access/services/kiosk/kiosk.service';
import { Outlet, ResponseDTO } from '@models/index';
import { Kiosk, KioskCreate } from '@models/kiosk/kiosk';
import { first, Subject, takeUntil } from 'rxjs';
import { OutletComponent } from "../outlet/outlet.component";
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { AppDialogComponent } from "../../shared/app-dialog/app-dialog.component";
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TextInputFieldComponent } from "../../shared/text-input-field/text-input-field.component";
import { PasswordFieldComponent } from "../../shared/password-field/password-field.component";
import { ZorroTableComponent } from "../../zorro-table/zorro-table.component";

@Component({
  selector: 'app-outlet-kiosk',
  standalone: true,
  imports: [OutletComponent, AppDialogComponent, TextInputFieldComponent, PasswordFieldComponent, ZorroTableComponent],
  templateUrl: './outlet-kiosk.component.html',
  styleUrl: './outlet-kiosk.component.css'
})
export class OutletKioskComponent {

  
  constructor(private kioskService: KioskService, private confirmDialogService: ConfirmDialogService, private outletService: OutletService, private toastr: ToastrService) {
    this.outletService.outlet$.pipe(takeUntil(this.$destroy)).subscribe((result) => {
      this.outlet = result as Outlet;
    });
    this.fetchKiosks(this.outlet.id);
   }

toggleAddKioskDialog() {
this.addKioskDialog = !this.addKioskDialog;
}

deleteKiosk(id: number) {
  this.confirmDialogService.open({
    title: 'Delete Kiosk',
    message: 'Are you sure you want to delete this kiosk?',
    confirmLabel: 'Yes, Delete',
    cancelLabel: 'No, Cancel',
    confirmColor: '#FF2230',
    cancelTextColor: '#580c8b',
    cancelBorderColor: '#580c8b'
  }).then((result) => {
    if (result) {
  this.kioskService.deleteKiosk(id).pipe(first()).subscribe({
    next: (result: ResponseDTO) => {
      if (result.status) {
        this.kiosks = this.kiosks.filter(kiosk => kiosk.id !== id);
        this.toastr.success('Successful Operation');
      }
      else{
        this.toastr.error(result.message);
      }
    },
  });
    }
  });
}


  kiosks: Kiosk[] = [];
  addKioskDialog: boolean = false;
  changeKioskPasswordDialog: boolean = false;
  outlet!: Outlet ;
  private $destroy = new Subject<void>();
  formSubmitted: boolean = false;

  tableCols: PrimeTableColumn[] = [
    { field: 'name', header: 'Name' },
    { field: 'userName', header: 'UserName' }
  ];

  createKioskForm = new FormGroup({
    name: new FormControl('', Validators.required),
    outletId: new FormControl(),
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  changePasswordForm = new FormGroup({
    password: new FormControl('', Validators.required),
    changePassword: new FormControl('', Validators.required),
  });



  fetchKiosks(outletId: number) {
    this.kioskService.getKiosksByOutlet(outletId.toString()).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.kiosks = result.data as Kiosk[];
        }
      },
    });
  }

  createKiosk() {
    this.formSubmitted = true;
    if(this.createKioskForm.invalid){
      return;
    }
    const data : KioskCreate = {
      name: this.createKioskForm.value.name as string,
      username: this.createKioskForm.value.username as string,
      password: this.createKioskForm.value.password as string,
      outletId: this.outlet.id
    } 
    this.kioskService.createKiosk(data).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.fetchKiosks(this.outlet.id);
          this.addKioskDialog = false;
          this.createKioskForm.reset();
          this.toastr.success('Successful Operation');
        }
        else{
          this.toastr.error(result.message);
        }
      },
      error: (error) => {
        this.toastr.error(error.message);
      }
    });
  }

}
