<app-user-layout (cartEventEmitter)="showCartDialog()" [showCart]="true"
    [cartCount]="this.cart.flat().length.toString()">

    <div class="flex flex-col w-full h-full">
        <div class="w-full h-full">
            <!-- Restaurant Header -->
            <div class="flex relative flex-col justify-center items-center w-full h-full bg-transparent">
                <span class="text-vendblocpurple dark:text-white font-bold text-2xl md:text-[50px] xl:text-5xl">{{
                    outlet?.store
                    }}</span>
                <span class="mb-5 text-sm font-semibold text-vendblocgrey dark:text-gray-300 md:text-lg xl:text-xl">{{
                    outlet?.name
                    }}</span>

                <div class="flex flex-col space-y-3 w-[75%] md:w-[40%] mt-2 md:mt-4 ">
                    <div class="flex relative items-center w-full">
                        <input [(ngModel)]="searchQuery" (ngModelChange)="onSearch()"
                            class="py-2 md:py-3 pl-12 px-1 rounded-2xl w-full bg-[#F7F3F9] dark:bg-gray-700 focus:outline-none text-vendblocpurple dark:text-white"
                            type="text" placeholder="Search Food" id="mobileSearch" />
                        <button class="absolute pl-2">
                            <span
                                class="w-8 text-lg font-bold text-gray-400 duration-200 pi pi-search hover:scale-110"></span>
                        </button>
                    </div>
                </div>
            </div>
            <!--  -->
            <!-- </div> -->

            <!-- Cart Dialog -->
            <p-dialog [(visible)]="cartDialog" [style]="{ width: '450px' }" header="Cart" [modal]="true"
                styleClass="p-fluid">
                <ng-template pTemplate="content">
                    <div class="m-0 space-y-6 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                        <ng-container *ngTemplateOutlet="cartCardTemplate"></ng-container>
                    </div>
                </ng-template>
            </p-dialog>

            <p-dialog [(visible)]="updateProfileDialog" [style]="{ width: '450px' }" header="Add Details" [modal]="true"
                styleClass="p-fluid">
                <ng-template pTemplate="content">
                    <div class="m-0 space-y-6 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                        <form (ngSubmit)="updateProfile()" #customerForm="ngForm">
                            <div class="flex flex-col space-y-3">
                                <div class="form_div">
                                    <label for="">Name</label>
                                    <input required 
                                        class="p-3 rounded-md border" 
                                        placeholder="Input Your Name"
                                        [(ngModel)]="customerName" 
                                        name="customerName"
                                        type="text" 
                                        required />
                                </div>
                                <div class="form_div">
                                    <button
                                        class="flex justify-center p-3 space-x-4 text-white duration-300 bg-vendblocpurple create_button hover:shadow-lg"
                                        type="submit">
                                        <span>Checkout</span>
                                    </button>
                                </div>
                                <div class="flex flex-col justify-between mt-4 w-full">
                                    <app-info
                                        textItem="This is to strictly for your invoices and order tracking"></app-info>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>

            </p-dialog>



            <!-- Content Div -->
            <div
                class="flex flex-row justify-between items-center px-2 pt-4 mb-20 space-x-3 w-full lg:px-4 xl:px-6 2xl:px-20 lg:space-x-6 md:items-start">
                <!-- Products Div -->
                <div class="flex flex-col md:w-[67%] w-full ">
                    <div class="flex flex-col space-y-0">
                        <span class="mb-1 font-bold dark:text-white">Food Categories</span>
                        <div class="flex overflow-x-auto py-2">
                            @for(category of filteredCategories; track category.id) {
                            <div (click)="categoryProducts($event, category.id)" class="category_item_div group"
                                [ngClass]="{'text-white bg-vendblocpurple':activeCategory === category.id, 'dark:border-gray-600': darkModeService.darkModeSignal() === 'dark'}">
                                <span
                                    class="flex px-1 whitespace-nowrap text-vendblocpurple dark:text-white group-hover:text-white"
                                    [ngClass]="{'text-white':activeCategory === category.id}">{{ category.name }}</span>
                                <span class="opacity-50 text-vendblocpurple dark:text-white group-hover:text-white"
                                    [ngClass]="{'text-white':activeCategory === category.id}">{{ category.quantity
                                    }}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                        @for(product of filteredProducts; track product.productId) {
                        <app-order-product-card [cardName]="product.product"
                            [iconBackgroundColor]="darkModeService.darkModeSignal() === 'dark' ? 'white' : 'vendblocpurple'"
                            [cardCount]="product.quantity.toString()" [icon]="'cart'" [product]="product"
                            (addToCartEvent)="addProductToCart(product)"
                            (incrementDecrementQuantity)="handleProductQuantityIncrementDecrement($event)"
                            (directQuantityChange)="handleDirectQuantityInput($event)"></app-order-product-card>
                        } @if(filteredProducts.length === 0) {
                        <div class="text-lg text-center md:text-xl">
                            <div class="flex justify-center items-center">
                                <span>No products found matching your search.</span>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <!-- My Cart -->
                <div class="md:w-[33%]" [ngClass]="cart.length === 0 ? 'hidden' : 'hidden md:block'">
                    <div
                        class="space-y-6 border dark:border-gray-700 md:flex md:flex-col border-grey-300 md:p-1 lg:p-3 xl:p-4">
                        <ng-container *ngTemplateOutlet="cartCardTemplate"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

</app-user-layout>

<ng-template #cartCardTemplate>
    <app-cart-card (repeatOrderEvent)="repeatOrder()" (deleteOrderEvent)="deleteOrder($event)"
        (quantityChangedEmitter)="onCartQuantityChange($event)" [ordersCost]="ordersCost" [outlet]="outlet"
        [enableDeliveryOptions]="false" (changeDeliveryAddressEvent)="toggleProfileModal()" [user]="user"
        [totalCost]="totalCost" [serviceCharge]="serviceCharge" [deliveryFee]="deliveryFee"
        [deliveryLocation]="deliveryLocation" (newOrder)="newOrder()"
        (deliveryModeChanged)="deliveryModeChanged($event)" (clearCart)="clearCart()"
        (removeFromCart)="removeFromCart($event)" (incrementDecrementEvent)="cartItemIncrementDecrement($event)"
        [cart]="cart" (checkout)="checkout()" [selectedDeliveryMode]="selectedDeliveryMode">
    </app-cart-card>

    @if(this.paid){
    <button (click)="printInvoice()" class="flex justify-center items-center w-full p-3 mt-4 space-x-2 
        text-vendblocpurple dark:text-white 
        border border-vendblocpurple dark:border-gray-400
        bg-transparent hover:bg-vendblocpurple hover:text-white
        dark:hover:bg-gray-700 dark:hover:border-gray-700
        rounded-lg transition-all duration-300 
        hover:shadow-lg hover:scale-[1.02] active:scale-[0.98]">
        <i class="text-lg pi pi-print"></i>
        <span class="font-medium">Print Invoice</span>
    </button>
    }
</ng-template>