import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.css'
})
export class FileUploadComponent {
  @Output() fileUploaded = new EventEmitter<File>();
  @Input() maxFileSize: number = 1000000; // 1MB in bytes
  @Input() name: string = '';
  @Input() formControl: FormControl = new FormControl();
  @Input() formSubmitted: boolean = false;
  @Input() label: string = 'Product';
  @Input() disabled: boolean = false;
  buttonString: string = 'Choose Image';
  fileSize: number = 0;

  get maxFileSizeFormatted(): number {
    return this.maxFileSize > 1000000 ? 
      this.maxFileSize / 1000000 : // Convert to MB
      this.maxFileSize / 1000;     // Convert to KB
  }

  get fileSizeFormatted(): number {
    return this.fileSize > 1000000 ? 
      this.fileSize / 1000000 : // Convert to MB
      this.fileSize / 1000;     // Convert to KB
  }

  get maxFileUnit(): string {
    return this.maxFileSize > 1000000 ? 'MB' : 'KB';
  }

  get fileUnit(): string {
    return this.fileSize > 1000000 ? 'MB' : 'KB';
  }

  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      this.fileSize = file.size;
      
      if (file.size > this.maxFileSize) {
        this.formControl?.setErrors({ fileSizeExceeded: true });
        this.formControl?.markAsDirty();
        input.value = ''; // Reset input
        this.buttonString = 'Choose Image';
        return;
      }
      
      this.buttonString = file.name;
      this.fileUploaded.emit(file);
      this.formControl?.setErrors(null);
    }
  }
}
