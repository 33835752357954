import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@data-access/services';
import { ToastrService } from 'ngx-toastr';

export const storeOwnerGuard: CanActivateFn = (route, state) => {
  const toastr = inject(ToastrService)
  const authService = inject(AuthService);
  const valid =  authService.getUserRole() === 'storeowner';
  if(!valid){
    toastr.error("Unauthorized");
  }
  return valid;
};

export const ShenorGuard: CanActivateFn = (route, state) => {
  const toastr = inject(ToastrService)
  const authService = inject(AuthService);
  const valid =  authService.getUserRole() === 'uncleshenor';
  if(!valid){
    toastr.error("Unauthorized");
  }
  return valid;
};


export const BusinessGuard: CanActivateFn = (route, state) => {
  const toastr = inject(ToastrService)
  const authService = inject(AuthService);
  const valid =  authService.getUserRole() === 'uncleshenor' || authService.getUserRole() === 'storeowner';
  if(!valid){
    toastr.error("Unauthorized");
  }
  return valid;
};

export const KioskGuard: CanActivateFn = (route, state) => {
  console.log('KioskGuard triggered for URL:', state.url);
  console.log('Route config:', route);
  const toastr = inject(ToastrService)
  const authService = inject(AuthService);
  const router = inject(Router);
  console.log('User role:', authService.getUserRole());
  const valid = authService.getUserRole() === 'kiosk';
  if(!valid){
    router.navigate(['/login']);
   // toastr.error("Unauthorized");
  }
  return valid;
};