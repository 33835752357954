import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderService, OutletService } from '@data-access/services';
import { Order, OrderReport, ResponseDTO } from '@models/index';
import { format } from 'date-fns';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { first } from 'rxjs';
import { OutletComponent } from '../outlet/outlet.component';
import { AppDialogComponent } from "../../shared/app-dialog/app-dialog.component";
import { PanelModule } from 'primeng/panel';
import { ToastrService } from 'ngx-toastr';
import { OrderInvoiceComponent } from "../../shared/order-invoice/order-invoice.component";
import { ZorroTableComponent } from "../../zorro-table/zorro-table.component";
import { TableColumn, tagDataTypes } from '@utils/index';
import { FieldType } from '@utils/index';

@Component({
  selector: 'app-outlet-report',
  standalone: true,
  templateUrl: './outlet-report.component.html',
  styleUrl: './outlet-report.component.css',
  imports: [OutletComponent, CardModule, PanelModule, FormsModule, ReactiveFormsModule, CalendarModule, DropdownModule, AppDialogComponent, OrderInvoiceComponent, ZorroTableComponent]
})
export class OutletReportComponent {
  closeDialog() {
    this.dialogVisible = false;
  }


  showDialog(index: number) {
    this.currentOrder = this.orders[index];
    this.dialogVisible = true;
  }

  constructor(private orderService: OrderService, private outletService: OutletService, private toastr: ToastrService) {
    this.outletService.outlet$.subscribe((result) => {
      this.outlet = result;
    });
  }
  total: number = 0;
  outlet: any;
  startDate : Date | undefined;
  endDate : Date | undefined;
  orders: Order[] = [];
  reports: object[] = [{ name: 'Order' }];
  currency: string = 'NGN';
  showConfigureDialog: boolean = false;
  maxDate: Date = new Date();
  dialogVisible: boolean = false;
  currentOrder!: Order;
  tableCols: TableColumn[] = [
    { field: 'amount', header: 'Amount' },
    { field: 'id', header: 'Order Id', isSearchable:true },
    { field: 'status', header: 'Status' },
    { field: 'paid', header: 'Paid',fieldType: FieldType.Tag, tagType: tagDataTypes.boolean },
    { field: 'deliveryMode', header: 'Delivery' },
    { field: 'formattedDate', header: 'Date' },];

  formatDate(date: Date){
    return format(date, 'dd/MM/yyyy');
  }

    queryOrders() {
      if(this.endDate && this.startDate && this.endDate < this.startDate){
        this.toastr.error('End date cannot be less than start date');
        return;
      }
      if(this.startDate && this.endDate){
        this.fetchOrders(this.outlet.id);
        this.toggleConfigureDialog();
      }
      else{
        this.toastr.error('Please select a date range');
      }
    }

  toggleConfigureDialog() {
    this.showConfigureDialog = !this.showConfigureDialog;
  }

  fetchOrders(id: number) {
    this.orderService.getOutletOrdersWithDate(id, this.startDate?.toISOString() ?? '', this.endDate?.toISOString() ?? '')?.pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const data = result.data as OrderReport;
          this.total = data.total;
          if (data?.orders?.length > 0) {
            this.toastr.success('Orders fetched successfully');
            this.orders = data.orders.map((o: Order) => ({ ...o, formattedDate: format(o.orderDate as Date, 'dd MMM yyyy'), customerName: o.customer.name }));
          }
          else{
            this.toastr.info('No orders found for the selected date range');
          }
        }
      },
      error: (e) => {
        console.log(e);
      }
    });
  }
}
