import { Routes } from '@angular/router';
import { OrderComponent } from './components/order/order.component';
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { MyOrdersComponent } from './components/my-orders/my-orders.component';
import { authGuard } from './utils/guards/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { VerifyPaymentComponent } from './components/verify-payment/verify-payment.component';
import { OutletComponent } from './components/outlet/outlet/outlet.component';
import { PickupComponent } from '@components/pickup/pickup.component';
import { ExploreComponent } from '@components/explore/explore.component';
import { BusinessGuard as businessGuard, KioskGuard } from '@utils/guards/role.guard';
import { LoginComponent, RegisterComponent } from '@components/index';
import { OrderV2Component } from '@components/order-v2/order-v2.component';
import { PageNotFoundComponent } from '@components/page-not-found/page-not-found.component';
import { KioskComponent } from '@components/kiosk/kiosk.component';
import { LoginKioskComponent } from '@components/auth/login-kiosk/login-kiosk.component';

export const routes: Routes = [

    {
        path: '', component: HomeComponent,
    },
    // Auth
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'kiosk-login', component: LoginKioskComponent
    },
    {
        path: 'register', component: RegisterComponent
    },
    {
        path: 'verify-email/:user/:code',
        loadComponent: () => import('@components/index').then((c) => c.VerifyEmailComponent)
    },
    {
        path: 'reset-password/:user/:code',
        loadComponent: () => import('@components/index').then((c) => c.ResetPasswordComponent),
    },
    {
        path: 'forgot-password',
        loadComponent: () => import('@components/index').then((c) => c.ForgotPasswordComponent)
    },
    {
        path: 'request-verification',
        loadComponent: () => import('@components/index').then((c) => c.EmailVerificationComponent)
    },
    // Auth


    {
        path: 'dashboard', component: DashboardComponent, canActivate: [authGuard]
    },
    {
        path: 'info', component: PersonalInfoComponent, canActivate: [authGuard]
    },
    // {
    //     path:'order/:id', component:OrderComponent
    // },
    {
        path: 'order/:slug', component: OrderV2Component
    },
    {
        path: 'kiosk/:slug', component: KioskComponent, canActivate: [KioskGuard]
    },
    {
        path: 'order-classic/:slug', component: OrderComponent
    },
    {
        path: 'verify-payment', component: VerifyPaymentComponent
    },
    {
        path: 'my-orders', component: MyOrdersComponent, canActivate: [authGuard]
    },
    {
        path: 'pickup', component: PickupComponent, canActivate: [authGuard]
    },

    {
        path: 'outlet/:id', component: OutletComponent, canActivate: [authGuard]
    },
    // Business Owners - All lazy Loaded
    {
        path: 'my-outlets',
        loadComponent: () => import('@components/index').then((c) => c.MyOutletsComponent),
        canActivate: [authGuard, businessGuard]
    },
    {
        path: 'outlet/:id/orders',
        loadComponent: () => import('@components/index').then((c) => c.OutletOrdersComponent),
        canActivate: [authGuard]
    },
    {
        path: 'outlet/:id/products',
        loadComponent: () => import('@components/index').then((c) => c.OutletProductsComponent),
        canActivate: [authGuard]
    },
    {
        path: 'outlet/:id/inventory',
        loadComponent: () => import('@components/index').then((c) => c.OutletInventoryComponent),
        canActivate: [authGuard]
    },
    {
        path: 'outlet/:id/settings',
        loadComponent: () => import('@components/index').then((c) => c.OutletSettingsComponent),
        canActivate: [authGuard]
    },
    {
        path: 'outlet/:id/customers',
        loadComponent: () => import('@components/index').then((c) => c.OutletCustomersComponent),
        canActivate: [authGuard]
    },
    {
        path: 'outlet/:id/report',
        loadComponent: () => import('@components/index').then((c) => c.OutletReportComponent),
        canActivate: [authGuard]
    },
    {
        path: 'outlet/:id/workers',
        loadComponent: () => import('@components/index').then((c) => c.OutletWorkersComponent),
        canActivate: [authGuard]
    },
    {
        path: 'outlet/:id/kiosk',
        loadComponent: () => import('@components/index').then((c) => c.OutletKioskComponent),
        canActivate: [authGuard]
    },
    //Business Moodle
    {
        path: 'explore', component: ExploreComponent
    },
    {
        path: 'become-dispatcher',
        loadComponent: () => import('@components/become-dispatcher/become-dispatcher.component').then((c) => c.BecomeDispatcherComponent),
        canActivate: [authGuard]
    },
    {
        path: 'dispatchers',
        loadComponent: () => import('@components/dispatchers/dispatchers.component').then((c) => c.DispatchersComponent),
        canActivate: [authGuard]
    },
    { 
        path: '**', component: PageNotFoundComponent
    },
];
