import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LoadingService } from '@data-access/services';
import { map, finalize } from 'rxjs/operators';

export const authGuard = (route: any) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const loadingService = inject(LoadingService);

  // Show loading spinner before auth check
loadingService.show();
console.log("Loading spinner shown");

  return authService.isAuthenticated$.pipe(
    map(isAuthenticated => {
      const url = route.routeConfig?.path || '';
      if (!isAuthenticated && !url.includes('login')) {
        router.navigate(['/login']);
        return false;
      }
      return true;
    }),
    // Hide loading spinner after auth check completes
    finalize(() => {
      loadingService.hide();
    })
  );
};
